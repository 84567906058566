const storage = {
  get(key) {
    try {
      return JSON.parse(window.sessionStorage.getItem(key));
    } catch (err) {
      return window.sessionStorage.getItem(key);
    }
  },
  set(key, value) {
    window.sessionStorage.setItem(
      key,
      typeof value == "object" ? JSON.stringify(value) : value
    );
  },
  remove(key) {
    window.sessionStorage.removeItem(key);
  },
  clear() {
    window.sessionStorage.clear();
  }
};
export default storage;
